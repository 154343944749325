.your-referral-code {
  padding: 10px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: color(text-grey-400);
  background: color(text-grey-800);
  border-radius: 8px;

  span {
    color: color(white);
    margin-left: 30px;
  }
}

.qr-code-referral {
  margin-top: 32px;
}
