.titan-form {
  &__cancle {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: color(text-grey-400) !important;
    border: 1px solid color(text-grey-500) !important;
  }
  &__forgot-password {
    font-weight: 600;
    font-size: 16px;
    text-decoration: underline;
  }
  &__item {
    display: flex;
    justify-content: center;
    &.flex-end {
      justify-content: flex-end;
    }
    &.other-method {
      margin-bottom: 12px;
      .ant-row {
        width: 100%;
      }
      .other-method-login {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        span {
          color: white;
          display: flex;
          padding: 0 24px;
          background-color: color(background-primary);
          z-index: 2;
        }
        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: color(border-primary);
          content: '';
        }
      }
    }
    .create-or-login {
      display: flex;
      align-items: center;
      span {
        color: #cccccc;
        font-size: 16px;
        font-weight: 400;
        padding-right: 4px;
      }
    }
    &.agree-term {
      .ant-form-item-row {
        width: 100%;
      }
      .agree-term__detail {
        display: flex;
        justify-content: flex-start;
        .term {
          font-weight: 600;
          font-size: 16px;
          text-decoration-line: underline;
          text-transform: capitalize;
          color: color(primary);
          &:hover {
            cursor: pointer;
          }
          @include respond-down(sm) {
            white-space: nowrap;
          }
        }
        span {
          font-weight: 400;
        }
      }
    }
    .other-login {
      display: flex;
      align-items: center;
    }
    &.full-width {
      .ant-row {
        width: 100%;
      }
    }
    &.slider {
      padding-left: 16px;
    }
    .total-price {
      display: flex;
      align-items: center;
      .form-label {
        margin-bottom: 0;
        font-size: 14px;
        color: color(text-grey);
      }
      .value {
        color: white;
        font-size: 18px;
        color: color(text-default);
        font-weight: 600;
        margin-left: 24px;
      }
    }
    &.actions {
      @include respond-down(sm) {
        width: 100%;
        .ant-form-item-row {
          width: 100%;
          .ant-form-item-control-input-content {
            display: flex;
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__button-other-method {
    height: 40px;
    padding: 0;
    width: 40px;
    margin-left: 20px;
    margin-right: 20px;
    @include respond-down(sm) {
      width: 43px;
      height: 42px;
    }
  }
  &__submit {
    margin-top: 8px;
    height: 44px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 600;
    font-size: 16px;
    color: white;
    &:hover,
    &:focus {
      color: white;
    }
    @include respond-down(sm) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .error-message {
    color: color(error-primary);
    margin-bottom: 16px;
  }

  // Form api connection
  &--api-connection {
    margin-top: 24px;
    .titan-form__submit {
      margin-top: 0;
    }
    &.config {
      width: 400px;
      margin: 24px auto 0 auto;
      @include respond-down(sm) {
        width: 100%;
      }
      .actions {
        button {
          margin-left: 12px;
          margin-right: 12px;
        }
      }
    }
  }
  .phone-number-detail {
    margin-top: 29px;
  }
  .box-qr-code {
    .qr-code {
      // width: 210px !important;
      // height: 210px !important;
    }
    .description {
      margin-top: 24px;
      margin-bottom: 24px;
      text-align: center;
    }
  }
  .copy-clipboard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    .value {
      font-size: 16px;
      font-weight: 600;
      color: color(primary);
    }
    button {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      background-color: transparent;
      border: 1px solid color(primary);
      border-radius: 50%;
      padding: 0;
    }
  }
  &--otp {
    &__top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 42px;
        object-fit: cover;
        @include respond-down(sm) {
          width: auto;
          height: 32px;
        }
      }
    }
    &__detail {
      width: 100%;
      height: 100%;
      background-color: color(background-third);
      padding: 16px 32px;
      border-radius: 8px;
      @include respond-down(sm) {
        padding: 16px;
      }
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px;
        color: white;
        text-align: center;
      }
      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: color(text-default);
        text-align: center;
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        .resend {
          color: color(text-grey);
          text-decoration: underline;
          font-size: 16px;
          font-weight: 600;
          &.active {
            color: color(primary);
            &:hover {
              cursor: pointer;
            }
          }
        }
        .income {
          margin-left: 12px;
          font-size: 16px;
          font-weight: 600;
          color: white;
        }
      }
    }
  }
  &.form-bot {
    .box-bot-info {
      width: 100%;
      background-color: color(background-third);
      padding: 8px 12px;
      border-radius: 8px;
      text-align: left;
      .description {
        margin-top: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: white;
      }
      .empty-value {
        margin-top: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: white;
      }
      .list-value {
        list-style-type: none;
        .value-item {
          display: flex;
          flex-direction: column;
          &:first-child {
            margin-top: 4px;
          }
          .value {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: white;
          }
        }
      }
    }
  }
  &.form-purchase-bot {
    .setup-bot {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__content {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}

.titan-form-item {
  display: flex;
  flex-direction: column;
  @include respond-down(sm) {
    margin-bottom: 12px;
  }
  label {
    font-weight: 600;
    font-size: 16px;
    color: color(text-label);
    line-height: 24px;
    margin-bottom: 4px;
  }
}

.filter-date-picker {
  &__popup {
    @include respond-down(sm) {
      max-width: 100vw;
    }
    .ant-picker-range-arrow {
      display: none;
    }
    .ant-picker-panel {
      border: none;
      position: relative;
      &:first-child {
        &::before {
          position: absolute;
          top: 24px;
          right: 0;
          width: 1px;
          height: calc(100% - 24px);
          content: '';
          background-color: color(border-primary);
        }
      }
    }
    .ant-picker-panel-container {
      background: color(background-primary);
    }
    .ant-picker-cell-disabled {
      &::before {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
    .ant-picker-header {
      color: white;
      padding: 24px;
      border-bottom: none;
      button {
        color: color(text-grey);
      }
      .ant-picker-month-btn,
      .ant-picker-year-btn {
        color: white;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .ant-picker-date-panel .ant-picker-body {
      padding: 0 24px;
    }
    .ant-picker-content {
      width: 260px;
      @include respond-down(sm) {
        width: 100%;
      }
      th {
        color: color(text-grey);
      }
    }
    .ant-picker-cell {
      color: color(text-grey);
      &::before {
        height: 32px;
      }
      // padding: 7px 0;
      .ant-picker-cell-inner {
        color: color(text-grey);
        height: 32px;
        line-height: 32px;
        font-size: 13px;
        font-weight: 500;
        min-width: 32px;
      }
    }
    .ant-picker-cell-in-view {
      &.ant-picker-cell-in-range {
        &::before {
          background-color: color(primary);
          height: 32px;
        }
        .ant-picker-cell-inner {
          color: color(text-color);
        }
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
      height: 32px;
      background-color: color(primary);
    }
    .ant-picker-date-panel
      .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
      .ant-picker-cell-inner::after {
      right: -2px;
    }
    // .ant-picker-cell-range-start {
    //   .ant-picker-cell-inner {
    //     color: color(text-color);
    //   }
    //   &.ant-picker-cell-selected {
    //     .ant-picker-cell-inner {
    //       color: color(text-grey);
    //     }
    //   }
    // }
    // .ant-picker-cell-range-end {
    //   .ant-picker-cell-inner {
    //     color: color(text-color);
    //   }
    //   &.ant-picker-cell-selected {
    //     .ant-picker-cell-inner {
    //       color: color(text-grey);
    //     }
    //   }
    // }
    .ant-picker-date-panel {
      width: 310px;
    }
    .group-picker-bottom {
      padding: 24px 12px;
      .error-picker {
        font-size: 16px;
        color: color(error-primary);
        margin-bottom: 16px;
      }
      .group-action-picker {
        display: flex;
        justify-content: flex-end;
        @include respond-down(sm) {
          flex-wrap: wrap;
          justify-content: center;
        }
        button {
          margin-left: 16px;
          @include respond-down(sm) {
            margin-bottom: 12px;
            margin-left: 8px;
            margin-right: 8px;
            &:last-child {
              order: 1;
              width: 100%;
              margin-left: 0;
              margin-right: 0;
            }
            &:first-child {
              order: 2;
            }
            &:nth-child(2) {
              order: 3;
            }
          }
        }
      }
    }
    .ant-picker-panels {
      @include respond-down(sm) {
        overflow-x: auto;
        width: 100%;
        @include customScrollTrack(4px, color(background-secondary), 4px);

        .ant-picker-date-panel {
          width: 100%;
        }
        .ant-picker-panel {
          &::before {
            display: none !important;
          }
        }
      }
    }
    &.common {
      padding-top: 0;
      .ant-picker-panel-container {
        border: 2px solid color(background-third);
      }
    }
  }
}

.form-label {
  font-weight: 600;
  font-size: 16px;
  color: color(text-label);
  line-height: 24px;
  margin-bottom: 4px;
  &__tooltip {
    margin-left: 4px;
    color: color(text-grey);
    font-size: 15px;
  }
}
.form-slider-item {
  .ant-form-item-control {
    padding-left: 12px;
  }
}
.form-item-label-space-between {
  label {
    width: 100%;
  }
  &__detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      text-decoration: underline;
    }
    .item {
      .label {
        font-size: 16px;
        font-weight: 400;
        color: color(text-grey);
      }
      .value {
        font-size: 16px;
        font-weight: 700;
        color: white;
        margin-left: 8px;
      }
    }
  }
}

.titan-close-modal {
  position: absolute;
  right: 8px;
  top: 6px;
  background: transparent;
  border: none;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &:focus,
  &:hover {
    background-color: transparent;
  }
}

.change-pass-form {
  &__top {
    margin-bottom: 32px;
  }
  &__title {
    color: color(gray-25);
    text-align: center;
  }
}

.withdraw-amount {
  position: relative;
  .btn-max {
    color: color(light-blue);
    position: absolute;
    right: 0;
    top: 6px;
  }
}
