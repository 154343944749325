.titan-sidebar {
  background-color: color(background-secondary);
  padding: 16px;
  height: 100vh;
  overflow-y: auto;
  @include customScrollTrack(8px, color(background-secondary), 4px);
  @include respond-down(sm) {
    right: -100vw;
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important;
    box-shadow: -4px 0 12px rgba(0, 0, 0, 0.4);
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__top {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    .logo {
      > img {
        height: 30px;
      }
    }
    .avatar {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: color(primary);
    }
  }
  &__divider {
    height: 1px;
    width: 100%;
    background-color: color(background-third);
    margin: 10px 0px;
  }
  .titan-menu {
    background: transparent;
    border-right: none;
    .ant-menu-item {
      color: white;
      display: flex;
      align-items: center;
      padding: 0 !important;
      padding-left: 13px !important;
      height: 44px;
      border-radius: 4px;
      margin-top: 16px;

      .ant-menu-item-icon {
        width: 20px;
        height: 20px;
        font-size: 20px !important;
        display: flex;
        align-items: center;
      }
      .ant-menu-title-content {
        font-size: 14px;
        font-weight: 500;
        color: color(text-gray-25);
        a {
          color: color(text-gray-25);
        }
      }
      &::after {
        border-right: none !important;
      }
      &:hover {
        background-color: color(primary);
      }
    }
    .ant-menu-item-selected {
      color: color(primary);
      background-color: transparent !important;
      &:hover {
        background-color: color(primary);
        .ant-menu-item-icon {
          color: color(primary);
        }
        .ant-menu-title-content {
          a {
            color: color(primary);
          }
        }
      }
      .ant-menu-title-content {
        a {
          color: color(primary);
        }
      }
    }
    &.ant-menu-inline-collapsed {
      .ant-menu-item {
        .ant-menu-title-content {
          width: 0;
          overflow: hidden;
          margin-left: 0;
          @include respond-down(sm) {
            width: auto;
            opacity: 1;
            margin-left: 16px;
          }
        }
      }
    }
    &__group {
      &:not(:first-child) {
        margin-top: 16px;
      }
      &__title {
        font-size: 12px;
        font-weight: 400;
        color: color(text-grey-500);
        margin-bottom: 4px;
        display: flex;
        margin-left: 12px;
        height: 14px;
        align-items: center;
        @include respond-down(sm) {
          margin-left: 0;
        }
      }
      .ant-menu-item {
        margin-top: 0;
      }
    }
    &--bottom {
      .ant-menu-item {
        margin-bottom: 0;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  &__filter {
    width: 100% !important;
    margin-bottom: 16px;
    @include respond-down(sm) {
    }
    .ant-select-selector {
      height: 44px !important;
      display: flex;
      align-items: center;
      background-color: color(background-secondary) !important;
      border: 1px solid transparent !important;
      padding: 0 13px !important;
      &:hover {
        border: 1px solid color(primary) !important;
      }

      .ant-select-selection-search-input {
        height: 44px !important;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: color(text-default) !important;
      }
      .titan-sidebar__filter__item {
        font-weight: 700 !important;
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        border: 1px solid color(primary) !important;
        box-shadow: none !important;
      }
    }
    .ant-select-arrow {
      color: white;
    }
    &__popup {
      border-radius: 8px !important;
      background-color: color(background-secondary);
      width: 208px !important;
      @include respond-down(sm) {
        min-width: 218px !important;
        width: 218px !important;
      }
      .ant-select-item {
        padding: 10px 12px !important;
        color: color(text-default);
        &:hover {
          background-color: color(primary);
          color: color(text-color);
        }
        &:first-child {
          border-radius: 8px 8px 0 0;
        }
        &:last-child {
          border-radius: 0 0 8px 8px;
        }
      }
      .ant-select-item-option-selected {
        background-color: transparent !important;
        color: color(primary);
        &:hover {
          color: color(primary);
        }
        .titan-sidebar__filter__item {
          font-weight: 700 !important;
        }
      }
      .ant-select-item-option-active {
        background-color: color(primary) !important;
        color: color(text-color) !important;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      svg {
        margin-right: 10px;
      }
    }
  }
  &.ant-layout-sider-collapsed {
    @include respond-down(sm) {
      right: -100vw;
    }
    .titan-menu {
      .ant-menu-item {
        padding: 0 !important;
        justify-content: center;
        @include respond-down(sm) {
          justify-content: flex-start;
          padding-left: 16px !important;
        }
        .ant-menu-title-content {
          @include respond-down(sm) {
            width: auto;
          }
        }
      }
    }
    .titan-menu__group__title {
      display: none;
      @include respond-down(sm) {
        display: block;
        margin-left: 12px;
      }
    }
    .titan-sidebar__filter {
      .ant-select-arrow {
        display: none;
        @include respond-down(sm) {
          display: block;
        }
      }
    }
    + .titan-layout__content {
      margin-left: 84px;
      @include respond-down(sm) {
        margin-left: 0;
      }
    }
  }
  &.active {
    @include respond-down(sm) {
      right: 0;
    }
  }
  &__bottom {
    display: none;
    @include respond-down(sm) {
      padding-left: 0;
      padding-right: 0;
      display: block;
    }
    .request-consultation {
      display: none;
      &.mobile {
        @include respond-down(sm) {
          border: 1px solid color(primary);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          padding-left: 12px;
          padding-right: 12px;
          height: 44px;
          margin-right: 24px;
          font-size: 16px;
          font-weight: 600;
          width: 100%;
          margin-bottom: 24px;
          margin-top: 32px;
        }
      }
    }
  }
}
