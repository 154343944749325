.commissions {
  &-top {
    padding: 16px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid color(text-grey-700);
    background: color(text-grey-800);
    margin-bottom: 24px;
    color: color(text-grey-400);

    &-border {
      border-right: 1px solid color(text-grey-700);
    }

    h2 {
      color: color(white);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 4px;
    }

    .ant-col {
      align-self: center;
      padding: 20px;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
        text-align: right;
      }
    }

    &-row {
      display: flex;

      &:first-child {
        margin-bottom: 12px;
      }

      .value {
        margin-left: auto;
        color: color(white);
      }
    }

    .btn-claim-commissions {
      margin-left: auto;
    }
  }
}
