.account {
  &-my-profile {
    padding-right: 12px;
  }

  &-referral {
    padding-left: 12px;

    &-box {
      display: flex;
      margin-bottom: 20px;
    }

    .your-referral-code {
      width: 100%;
      margin-right: 12px;
    }

    &-qrcode {
      height: calc(100% - 128px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .ant-btn {
        margin-top: 16px;
      }
    }
  }

  &-box {
    height: 100%;
    padding: 20px;
    border-radius: 16px;
    background: color(gray-950);

    h2 {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: color(text-grey-25);
    }

    hr {
      border-bottom: none;
      border-left: none;
      border-right: none;
      border-top: 1px solid color(text-grey-700);
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .box-avatar {
      width: 160px;
      height: 160px;
      margin: 0 auto 24px auto;
      position: relative;

      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        object-fit: cover;
      }

      .btn-camera {
        position: absolute;
        bottom: 3px;
        right: 3px;
        height: 40px;
        width: 40px;
        padding: 0px;
      }

      .loading-spin {
        position: absolute;
        top: 60px;
        left: 60px;
      }
    }
  }

  &-info {
    border-radius: 12px;
    border: 1px solid var(--gray-700, #344054);
    background: var(--gray-800, #1d2939);
    padding: 16px;

    &-row {
      display: flex;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0px;
      }

      label {
        color: color(text-grey-400);
      }

      p {
        margin-left: auto;
      }
    }
  }

  &-actions {
    text-align: center;
    margin-top: 20px;
  }
}

.block-wallet {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  @include respond-down(sm) {
    margin-bottom: 24px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .go-detail {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      @include respond-down(sm) {
        margin-bottom: 12px;
      }
      a {
        color: color(primary);
        text-decoration: underline;
        font-size: 16px;
        font-weight: 600;
      }
      svg {
        color: color(text-grey);
        margin-left: 10px;
      }
    }
  }
  &__detail {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url(../../assets/img/background-wallet.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 8px;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
    @include respond-down(md) {
      flex-direction: column;
    }
    @include respond-down(sm) {
      flex-direction: column;
    }
    // padding: 24px;
    .box-wallet {
      width: 100%;
      display: flex;
      @include respond-down(md) {
        width: 100%;
      }
      @include respond-down(sm) {
        flex-direction: column;
        width: 100%;
      }
      &__item {
        width: 100%;
        position: relative;
        padding: 24px;
        border-radius: 8px;
        display: flex;
        justify-items: center;
        align-items: center;
        padding-right: 24px;
        @include respond-down(md) {
          width: 100%;
          padding: 24px;
        }
        &:not(:last-child) {
          &::before {
            position: absolute;
            right: 0;
            top: 24px;
            width: 1px;
            height: calc(100% - 48px);
            content: '';
          }
        }
        @include respond-down(sm) {
          width: 100%;
          padding: 16px;
          flex-direction: row;
          align-items: center;
          &:not(:last-child) {
            &::before {
              top: 100%;
              width: calc(100% - 32px);
              height: 1px;
              right: auto;
              z-index: 1;
            }
          }
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: color(background-secondary-bold);
          margin-right: 24px;
        }
        .info {
          @include respond-down(sm) {
            margin-left: 12px;
          }
          .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: color(text-grey);
            svg {
              margin-left: 6px;
              font-weight: 700;
            }
          }
          .volume {
            font-weight: 700;
            line-height: 36px;
            color: white;
            font-size: fontSize(number-primary, lg);
            @include respond-down(sm) {
              font-size: fontSize(number-primary, sm);
            }
          }
          .percentage {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            &.up {
              color: color(green-primary);
            }
            &.down {
              color: color(error-primary);
            }
          }
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      margin-left: auto;
      @include respond-down(md) {
        margin-bottom: 24px;
        width: 100%;
        padding: 0 12px;
      }
      @include respond-down(sm) {
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
      }
      .btn {
        border: 1px solid color(background-primary);
        border-radius: 40px;
        @include respond-down(md) {
          margin-left: 8px;
          margin-right: 8px;
          width: 50%;
        }
        @include respond-down(sm) {
          width: 100%;
          margin-right: 8px;
          margin-left: 8px;
        }
        &--fill {
          background-color: color(gray-900);
          color: color(white);
        }
      }
    }
  }
}
