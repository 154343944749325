.titan-table {
  height: 100%;
  padding: 5px 0px;
  ::-webkit-scrollbar-thumb {
    background-color: color(primary) !important;
  }
  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  .ant-table-cell-fix-right {
    background-color: color(background-primary) !important;
  }
  .ant-table {
    background-color: transparent;
    color: color(text-default);
    font-size: 16px;
    .ant-table-cell-scrollbar {
      display: none;
    }
    .ant-table-thead {
      tr {
        th {
          font-size: 14px;
          padding: 16px 12px;
          background-color: transparent;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: color(text-grey-500);
          border-bottom: 1px solid color(border-grey-700);
          &.ant-table-column-sort {
            background: color(background-primary);
          }
        }
      }
    }
    .ant-table-tbody {
      tr {
        &.ant-table-placeholder {
          &:hover {
            td {
              background: transparent;
            }
          }
        }
        td {
          border-bottom: 1px solid color(border-grey-700);
          padding: 16px 12px;
          font-size: 14px;
          &.ant-table-column-sort {
            background: color(background-primary);
            &.ant-table-cell-row-hover {
              background: color(primary);
              color: color(text-color);
            }
          }
        }
        .ant-table-cell-row-hover {
          background: color(primary);
          color: color(text-color);
          .titan-chip {
            &.pending {
              border: 1px solid color(border-primary);
              color: color(text-color);
            }
          }
          .btn--text {
            span {
              color: color(text-color);
            }
          }
        }
      }
    }
    .ant-table-container {
      &::before,
      &::after {
        box-shadow: none !important;
      }
    }
    .ant-table-content {
      overflow-x: auto;
      overflow-y: hidden;
      @include customScrollTrack(8px, color(background-secondary), 4px);
    }
    .ant-checkbox-inner {
      background-color: transparent;
      border: 1px solid white !important;
      border-radius: 4px;
    }
    .ant-checkbox-checked::after {
      border: unset;
    }
  }
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
      .ant-table {
        min-height: calc(100% - 70px);
      }
    }
  }
  // Empty box center
  &--empty {
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .ant-table {
          height: 100%;
          .ant-table-container {
            height: 100%;
            .ant-table-content {
              height: 100%;
              table {
                height: 100%;
                min-height: 400px;
              }
            }
          }
        }
      }
    }
  }

  .table-action-wrapper {
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .table-action-item {
      :hover {
        cursor: pointer;
      }
    }
  }
  &__expand-icon {
    cursor: pointer;
    max-height: 24px;
    margin-right: 7px;
  }
}

.titan-box-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include respond-down(sm) {
    justify-content: center;
    align-items: center;
    max-width: calc(100vw - 100px);
  }
  .title {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 700;
    color: white;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: color(text-grey-700);
  }
}

.ant-table-cell-with-append {
  display: flex;
  align-items: center;
}
